// Override default variables before the import
$primary: #1db0ea;

$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1000px,
);

// Import Bootstrap and its default variables
@import "~bootstrap/scss/bootstrap.scss";


.btn-primary {
    color: #fff;

    &:hover {
        color: #fff;
    }
}

.btn-outline-primary {
    &:hover {
        color: #1db0ea;
        background-color: #1db0ea46;
    }
}

.btn-check:checked + .btn-outline-primary {
    color: #fff;
}

.bg-main {
    background-color: #f5f5f5;
}

.nav-link {
    border: 0;
    background: none;

    &:hover {
        border-top: 4px solid $primary;
        padding-top: 4px;
    }

    &.active {
        color: #1db0ea !important;
        border-top: 4px solid $primary;
        padding-top: 4px;
    }
}

div.env-info {
    position: fixed;
    left: 50%;
    top: 0;
    font-size: 15px;
    padding: 3px;
    font-weight: bold;
    background: yellow;
    z-index: 10000;
}

.price-container {
    display: flex;
    gap: 1.5rem;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    
    & > * {
        flex-shrink: 0;
    }
}

.fs-7 {
    font-size: 0.875rem;
}

.form-select-transparent {
    background-color: transparent;
    border: transparent;

    &:focus {
        background-color: #FFF !important;
    }
}

.form-select {
    border-color: #555555;
    height: 46px;
    font-size: 1.125rem;
    appearance: none;
    padding-right: 3.25rem;

    &:hover {
        background-color: #bed5fb;
        border: transparent;
    }

    &:focus {
        background-color: #FFF !important;
    }

    option:focus {
        background: #bed5fb !important;
    }
}

.w-auto-responsive {
    width: auto;
    max-width: 100%;
}

// toogle button
.toggleContainer {
    --main-color: #1db0ea;

    position: relative;
    display: inline-grid;
    grid-template-columns: repeat(2, minmax(140px, 1fr));
    width: fit-content;
    border: 1px solid var(--main-color);
    border-radius: 20px;
    background: #fff;
    font-weight: 400;
    color: var(--main-color);
    min-height: 40px;
    cursor: pointer;

    &::before {
        content: '';
        position: absolute;
        width: 50%;
        height: 100%;
        left: 0;
        border-radius:20px;
        background: var(--main-color);
        transition: all 0.1s;
    }

    div {
        padding: 6px;
        text-align: center;
        z-index: 1;
    }
}

.toggleCheckbox {
    --main-color: #1db0ea;
    --transition-duration: 0.1s ;

    display: none;

    &:checked + .toggleContainer::before {
        left: 50%;
    }

    &:checked + .toggleContainer div:first-child{
        color: var(--main-color);
        transition: color var(--transition-duration);
    }

    &:checked + .toggleContainer div:last-child{
        color: #fff;
        transition: color var(--transition-duration);
    }

    & + .toggleContainer div:first-child{
        color: #fff;
        transition: color var(--transition-duration);
    }

    & + .toggleContainer div:last-child{
        color: var(--main-color);
        transition: color var(--transition-duration)
    }
}

.loading-button {
    font-size: 12px;
    color: #fff !important;
    display: flex;
    line-height: 1;
}

.btn-circle {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    aspect-ratio: 1/1;
    line-height: 1;
    font-size: calc(1.3rem + 0.6vw) !important;
    border-radius: 50rem;
    padding: 0;
    width: 40px;
    height: 40px;
  }
  .btn-circle.btn-lg {
    width: 50px;
    height: 50px;
    padding: 10px 16px;
    font-size: 18px;
    line-height: 1.33;
  }
  .btn-circle.btn-xl {
    width: 70px;
    height: 70px;
    font-size: 24px;
  }

  .has-tooltip {
    position: relative;
  }

  .has-tooltip::before {
    background: #222;
    border-radius: 5px;
    color: #FFF;
    content: attr(aria-label);
    display: inline-block;
    font-weight: normal;
    opacity: 0;
    padding: 10px;
    pointer-events: none;
    position: absolute;
    top: -100%;
    white-space: nowrap;
    width: auto;
    z-index: 2;
    transition: all .15s ease-out;
    font-size: 0.75rem;
  }

  .has-tooltip:hover:before,
  .has-tooltip:focus:before {
    opacity: 1;
  }

  .has-tooltip:after {
    border: 10px solid transparent;
    border-top-color: #222;
    content: "";
    height: 0;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: -10px;
    transition: all 0s ease-out 0s;
    width: 0;
    z-index: 1;
  }

  .has-tooltip:hover:after,
  .has-tooltip:focus:after {
    opacity: 1;
    -webkit-transition: all .15s ease-out .1s;
    transition: all .15s ease-out .1s;
  }

  .loader::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    border: 2px solid #FFF;
    border-radius: 50%;
    animation: loader 1.2s ease infinite;
    clip-path: polygon(50% 0%, 50% 50%, 100% 20%, 100% 0%);
  }
  
  @keyframes loader {
	0%   { transform: rotate(0deg); }
	100% { transform: rotate(360deg); }
}

.loader-square {
    position: relative;
  }

  .loader-square svg {
    position: absolute;
    left: 0;
    top: 0; 
    height: 100%;
    width: 100%; 
  }
  
  .loader-square rect {
    fill: none;
    stroke: none;
    stroke-dasharray: 100%, 200%;
    animation: dash 7s linear infinite;
  }

@keyframes dash {
  0% {
    stroke-width: 3;
    stroke: #fff;
    stroke-dashoffset: 100;
  }
  100%{
    stroke-width: 3;
    stroke: #fff;
    stroke-dashoffset: 1000;
  }
  
}

.icon-power {
    margin-top: -3px;
}

.btn-rounded {
    border-radius: 100vw;
}

.btn-rounded-start {
    border-start-start-radius: 100vw !important;
    border-end-start-radius: 100vw !important;
    border-inline-end: none;
}

.btn-rounded-end {
    border-end-end-radius: 100vw !important;
    border-start-end-radius: 100vw !important;
    border-inline-start: none;
}

.btn-rounded-end:focus, .btn-rounded-start:focus,
.btn-rounded-end:active, .btn-rounded-start:active,
.btn-rounded-end:active:focus, .btn-rounded-start:active:focus {
    box-shadow:  none;
}

.btn-rounded-end:hover, .btn-rounded-start:hover {
    background: transparent
}


.btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: gray;
    border-color: #ced4da;
}

@mixin switch($res: 'sm') {
    $index: 1rem;
    $mainVal: 1rem;
  
    @if $res == 'md' {
        $index: 2rem;
        $mainVal: 1.5rem;
    } @else if $res == 'lg' {
        $index: 3rem;
        $mainVal: 2rem;
    } @else if $res == 'xl' {
        $index: 4rem;
        $mainVal: 2.5rem;
    }

    //margin-bottom: $index / 2; /* JUST FOR STYLING PURPOSE */

    .form-check-input {
        height: $mainVal;
        width: calc(#{$index} + 0.75rem);
        border-radius: $mainVal * 2;
    }
}

.form-check-input {
  clear: left;
}

// YOU CAN PUT ALL RESOLUTION HERE
// sm - DEFAULT, md, lg, xl

.form-switch.form-switch-sm {
    @include switch();
}

.form-switch.form-switch-md {
    @include switch('md');
}

.form-switch.form-switch-lg {
    @include switch('lg');
}

.form-switch.form-switch-xl {
    @include switch('xl');
}

.heading {
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 1rem;

    @include media-breakpoint-up(md) { 
        margin-bottom: 0.5rem;
    }
}

.container-user-portal {
    max-width: 900px;
    margin: auto;
}

.btn {
    font-size: 0.875rem;
    padding-top: 0.55rem !important;
    padding-bottom: 0.55rem !important;
}

.btn-fix-size {
    width: 100%;

    @include media-breakpoint-up(md) { 
        width: 250px;
        max-width: 100%;
        
    }
}
.fw-semibold {
    font-weight: 600;
}

.row-settings {
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.alert {
    color: currentColor;
}

.navbar {
    font-size: 1.125rem;
}

.shutdown .form-control {
    --color: #1db0ea;

    background-color: #fff;
    opacity: 1;
    border-inline-start:none;
    border-inline-end:none;
    border-color: var(--color);
  }

.shutdown .form-control:disabled, .shutdown .form-control.shutdown[readonly] {
    --color: #bcbcbc;

    color: var(--color);
  }

.label-disabled {
    color: #bcbcbc;
}

.p-6 {
    padding: 2.5rem !important;
}

.p-0 {
    padding: 0 !important;
}

.form-pwd {
    background-color: transparent !important;
    border: 0;
    padding: 0;
}