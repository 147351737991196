@import '~bootstrap-icons/font/bootstrap-icons';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.navbar-toggler {
  border: none;
  outline: none;
}
  
.navbar-toggler:focus {
  box-shadow: none;
}

.navbar .navbar-text {
  cursor: text;
}

.login-container {
  width: 360px;
}